import CountrySelector from 'components/compaigns/CountrySelector';
import Search from 'components/global/Search';
import ToggleBtn from 'components/global/ToggleBtn';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCategory, setKeyword, setTargetCountry } from 'redux/reducers/compaignReducer';



const Filters = ({ categories }) => {
    const dispatch = useDispatch();
    const [ country , setCountry ] = useState('');
  

    useEffect(() => {
        if(country) {
            dispatch(setTargetCountry(country));
        }
    }, [country]);

    const categoryChangeHandler = (e) => {
        dispatch(setCategory(e.target.value));
    }

    const searchFetcher = (value) => {
        dispatch(setKeyword(value));
    }

    const clearAllFilterHandler = () => {
        dispatch(setKeyword(''));
        dispatch(setTargetCountry(''));
        dispatch(setCategory(''));
    }

    return (
        <div>
            
            <div className='flex sm:flex-row flex-col items-center gap-2 bg-pure rounded-md mt-4 py-4 px-2'>
                <div className='sm:flex-1 w-full sm:block hidden'>
                    <Search 
                    fetcher={searchFetcher}
                    />
                </div>
                <div className='flex-1 w-full sm:flex-row flex-col flex gap-2'>
                    <div className="flex-1">
                    <CountrySelector
                    setCountry={setCountry}
                    placeholder='Country'
                    />
                    </div>
                    <select
                    className='select-box py-2 flex-1 border-primary w-full'
                    onChange={categoryChangeHandler}
                    >
                    <option
                    value=''
                    >
                        All
                    </option>
                    {
                        categories?.map(item => (
                            <option 
                            key={item?._id}
                            value={item?._id}
                            >
                                {item?.name}
                            </option>
                        ))
                    }
                    </select>
                </div>
            </div>
            <div className='flex items-center justify-between gap-4 mt-2 text-gradient'>
                <div className='text-primary font-medium sm:text-sm text-xs'>
                    <button 
                    onClick={clearAllFilterHandler}
                    >
                        Clear All Filters
                    </button>
                </div>
                {/* <div className='flex items-center gap-2 sm:text-sm text-xs '>
                    <p>Show Paused Offers</p>
                    <ToggleBtn />
                </div>   */}

            </div>
        </div>
    )
}

export default Filters