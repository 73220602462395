import React from 'react'

const GoogleSvg = () => {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_437_589)">
<path d="M28.2268 14.8225C28.2268 13.8709 28.1496 12.914 27.985 11.9778H14.7798V17.3689H22.3418C22.028 19.1077 21.0197 20.6458 19.5433 21.6232V25.1213H24.0548C26.7041 22.6829 28.2268 19.082 28.2268 14.8225Z" fill="#4285F4"/>
<path d="M14.7798 28.501C18.5557 28.501 21.7399 27.2612 24.06 25.1213L19.5485 21.6232C18.2933 22.4771 16.6729 22.9607 14.7849 22.9607C11.1325 22.9607 8.03572 20.4966 6.92456 17.1837H2.26904V20.7898C4.64567 25.5174 9.48639 28.501 14.7798 28.501Z" fill="#34A853"/>
<path d="M6.91941 17.1837C6.33297 15.4449 6.33297 13.5621 6.91941 11.8234V8.21729H2.26904C0.283368 12.1732 0.283368 16.8339 2.26904 20.7898L6.91941 17.1837Z" fill="#FBBC04"/>
<path d="M14.7798 6.04127C16.7757 6.01041 18.7048 6.76146 20.1504 8.14012L24.1474 4.14305C21.6165 1.76642 18.2573 0.45979 14.7798 0.500943C9.48638 0.500943 4.64567 3.48459 2.26904 8.21728L6.91942 11.8234C8.02542 8.50536 11.1274 6.04127 14.7798 6.04127Z" fill="#EA4335"/>
</g>
<defs>
<clipPath id="clip0_437_589">
<rect width="28" height="28" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>

        </div>
    )
}

export default GoogleSvg