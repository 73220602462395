import GoogleSvg from 'assets/svgs/GoogleSvg';
import MobileSvg from 'assets/svgs/MobileSvg';
import CountrySelector from 'components/compaigns/CountrySelector';
import NameInput from 'components/global/NameInput'
import PasswordInput from 'components/global/PasswordInput';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { register } from 'redux/actions/authActions';


const RegisterForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const { loading } = useSelector(state => state.auth);

    const [country , setCountry] = useState('');
    const [registerData , setRegisterData] = useState({
        username : '' , 
        email : '' , 
        confirmPassword : '' , 
        password : '' , 
        referrer : searchParams.get('ref_code') || ''
    });

    const submitHandler = e => {
        e.preventDefault();
        if(registerData.password !== registerData.confirmPassword) {
            return toast.error('Passwords are not matched.')
        }
        if(!country) return toast.error('Country is required.');
        const { username , email , password , referrer } = registerData;
        const data = { username , email , password , role : 'user' , referrer , country};
        dispatch(register(data , navigate ));
    }

    return (
        <div>
            <form 
            className='flex flex-col gap-4'
            onSubmit={submitHandler}
            >
                <NameInput
                label='Username'
                placeholder='Enter username'
                data={registerData}
                setData={setRegisterData}
                name='username'
                minLength={3}
                maxLength={40}
                required
                />
                <NameInput
                type='email'
                label='Email'
                placeholder='Enter Email'
                data={registerData}
                setData={setRegisterData}
                name='email'
                maxLength={50}
                required
                />
                <div className='input-group'>
                    <label htmlFor="">
                        Country
                    </label>
                    <CountrySelector 
                    setCountry={setCountry}
                    />
                </div>
                <NameInput
                label='Sponser'
                placeholder='Enter sponser code'
                data={registerData}
                setData={setRegisterData}
                name='referrer'
                maxLength={30}
                optional={true}
                readOnly={searchParams.get('ref_code')}
                />
                <PasswordInput
                label='Password'
                placeholder='Enter password'
                data={registerData}
                setData={setRegisterData}
                name='password'
                minLength={6}
                maxLength={50}
                required
                />           
                <PasswordInput
                label='Confirm Password'
                placeholder='Enter password again'
                data={registerData}
                setData={setRegisterData}
                name='confirmPassword'
                minLength={6}
                maxLength={50}
                required
                />
                <div className='mt-4'>
                    <button 
                    className="btn-primary py-2 px-4 w-full"
                    disabled={loading}
                    >
                        {
                            loading 
                            ? 
                                <ClipLoader size={20} color='white' />
                            : 
                                'REGISTER'
                        }
                    </button>
                </div>
            </form>
            <div className='my-6 text-center'>
            -------------- or ---------------
            </div>
            <div className='flex sm:gap-2 gap-4 justify-between sm:flex-row flex-col'>
                <div>
                    <button className='flex items-center justify-center gap-2 py-2.5 px-3 bg-pure rounded-md border border-transparent hover:border-primary text-sm font-medium w-full'>
                        <MobileSvg />
                        <span>Login With Phone</span>
                    </button>
                </div>
                <div className=''>
                    <button className='flex items-center justify-center gap-2 py-2.5 px-3 bg-pure rounded-md border border-transparent hover:border-primary text-sm font-medium w-full'>
                        <GoogleSvg />
                        <span>Login With Google</span>
                    </button>
                </div>
            </div>
            <div className="mt-6 text-sm text-center">
                Already have an account <Link 
                to='/login'
                className='text-red-500 font-medium hover:underline'
                >Login here</Link>
            </div>


        </div>


    )
}

export default RegisterForm